import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getDataObtenerUno, addEquipments, getDataList } from "../../api/common";
import { CHILD } from "../../constants";
import { useForm } from "../../hooks/useForm";
import { Alertassw } from "../Alertas/Alertassw";

const AddEquipments = ({ setEstadoModal, ot,refresh, setRefresh, eqInput=undefined, setEstadomodalIn }) => {
  console.log(eqInput)
  const [values, handleInputChange] = useForm({
    serie: null,
    comment: null,
  });

  const navigate = useNavigate()

  const [eq, setEq] = useState({
    equipos:eqInput,
    extern:!!eqInput
  });
  console.log(eq)

  const handleGetEquipment = async (e) => {
    e.preventDefault();
    console.log(values);
    try {
      const data = await getDataList("Equipos", {conditions:{...values,cliente:ot.cliente._id,comment:undefined}, fields:{}, options:{}});
      setEq({ ...eq, equipos:data.data.data, error: false });
       if (!data.data.data) setEq({ error: true });
      setRefresh(!refresh)
    } catch (error) {
      setEq({ ...eq, error: true });
    }
  };

  const handleAddEquipment = async (e) => {
    e.preventDefault();
    const eqPayload = eq.equipos.map(item => ({equipo:item.equipo,serial:item.serie}));
    console.log(eqPayload)
    try {
      const body = {
        comment: values.comment,
        equipos: eqPayload,
      };
      setEstadomodalIn(true)
      const data = await addEquipments(ot?._id||'1234', ot.numeroOrden, CHILD, body);
      console.log(data);
      setRefresh(!refresh)
      setEstadomodalIn(true)
      Alertassw('ok','Equipos adicionados exitosamente')
      setEstadoModal(false)
      if(eq.extern) navigate('/Ot')
    } catch (error) {
      
      let messageDetail = ``
      error.response?.data?.detail.objects?.forEach((data)=>{
          messageDetail += `»${data.item.nombre} - ${data.serie} \n`
      })
      Alertassw('toast',error?.response?.data?.detail.message || "Error adicionando equipo", messageDetail,'error',10000)
    }
  };

  return (
    <div>
      <form>
      <div className="flex">
        <div className="form_group " >
          <div className="form_group_label">Serial del Equipo</div>

            <input type="text" name="serie" onChange={handleInputChange} />
           
          </div>
          <div onClick={handleGetEquipment} className="center-all ml-3 ">
              <i class="bi bi-search"></i>
            </div>   
        </div>
      </form>
      { (eq?.equipos?.length >0 && !eq?.error) ? (
        <>
          {eq.equipos.map((item,index)=>(
            <div className="border-base mt-5 text-center position-relative " style={{marginRight:"10%"}}>
            <div
              className="general-close-button mr-2"
              onClick={() => {
          
              eq.equipos.splice(index,1)
              setEq({...eq})
              }}
            >
              x
            </div>
            <div>
              {" "}
              <>{eq.extern?(item.item):(item.item?.nombre)}</> - {item.marca} - {item.modelo}
            </div>
          </div>))}
          <form>
            <div className="form_group ">
              <div className="form_group_label">Observación</div>
              <input type="text" name="comment" onChange={handleInputChange} />
            </div>

            <button className="btn-btn-quaternary" onClick={handleAddEquipment}>
              Adicionar
            </button>
          </form>
        </>
      ) : (eq?.error || eq?.equipos?.length === 0 )? (
        <div>Equipo no encontrado</div>
      ) : null}
    </div>
  );
};

export default AddEquipments;
