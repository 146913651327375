import { app } from "../firebase"
import moment from "moment"


export const 
saveFile = async(target,type='images') =>{
    console.log(target)
    let file = target.files[0]
    console.log(file)
    const ext = file.name.split('.')[1]
    console.log(ext)
    const storageRef = app.storage().ref()
    console.log(storageRef)
    const pathFile = storageRef.child(`${type}/${moment().unix()}.${ext}`)
    console.log(pathFile)
    await pathFile.put(file)
    console.log(pathFile)
    return  await pathFile.getDownloadURL()
}

const singleDelete = async(url) =>{
    let ref =  app.storage().refFromURL(url)
    await ref.delete()
}

export const deleteFile = async(files) =>{
    
try{
   if( files && Array.isArray(files)) await Promise.all(files.forEach(async(url)=>{
       await singleDelete(url)
    }))
    else if(files) await singleDelete(files)
}
catch(error){
    //console.log(error)
}
}