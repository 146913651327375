import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { saveFile } from '../../helpers/firebase';
import { actualizarDatos } from '../../api/common';

export const OtrosDocAdjuntos = ({ show, handleClose, dataPatron }) => {
  const [bodyDocument, setBodyDocument] = useState({});
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({ name: '', file: '' });

  console.log('Data_Patron: ',dataPatron)
  const handleFileChange = (e) => {
    setFile(e.target);
  };

  const handleNameChange = (e) => {
    setBodyDocument({...bodyDocument,
      nombre: e.target.value
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: '', file: '' };

    if (!bodyDocument.nombre) {
      newErrors.name = 'El nombre del documento es obligatorio.';
      valid = false;
    }

    if (!file) {
      newErrors.file = 'Debe seleccionar un archivo.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const 
  handleSubmit = async() => {
    if (validateForm()) {
      // Implementar la lógica para manejar el archivo y el nombre del documento
      try {
        console.log('File:', file);
        //const link = await saveFile(file,'documents')
        //console.log('Archivo:', link);

        //bodyDocument.documento=link
        //console.log('Document: ', bodyDocument)
        //dataPatron?.otrosDocumentos.push(bodyDocument)
        console.log('Nuevo array',)
        /*const actualizarPatron = await actualizarDatos('Patrones',{...dataPatron,
          otrosDocumentos:[...dataPatron.otrosDocumentos, ...dataPatron?.otrosDocumentos.push(bodyDocument)]
      },dataPatron._id)*/
        
      //console.log('Patron Actualizado:', actualizarPatron);
      } catch (error) {
        console.log(error)
      }
      // Cerrar el modal
      handleClose();
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Cargar Documento</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formDocumentName">
            <Form.Label>Nombre del Documento</Form.Label>
            <Form.Control
              type="text"
              placeholder="Ingrese el nombre del documento"
              value={bodyDocument?.nombre}
              onChange={handleNameChange}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formFileUpload">
            <Form.Label>Seleccionar Archivo</Form.Label>
            <Form.Control
              type="file"
              onChange={handleFileChange}
              isInvalid={!!errors.file}
            />
            <Form.Control.Feedback type="invalid">
              {errors.file}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!bodyDocument?.nombre || !file} // Deshabilitar si falta algún campo
        >
          Cargar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


