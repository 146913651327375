import { useEffect, useState } from "react"
import { Modales } from "../Modales"
import EditEqListado from "./EditEqListado"
import FormEquipments from "./FormEquipments"
import Select from "react-dropdown-select"
import { getDataList } from "../../api/common"




const AddedEquipments = ({setEquipos, equipos,del,  selectitem, handledata, add}) =>{
   const [estadoModal, setEstadomodalEd]=useState(false)
   const [equipoInfo, setEquipoinfo]=useState()

 console.log(equipos)
   const Editequipo=(index)=>{
    setEquipoinfo([equipos[index],{index}])
    setEstadomodalEd(true)

   }
    return (
            <div >
              <Modales
                estado={estadoModal}
                cambiarestado={setEstadomodalEd}
                titulo={'Editar equipo listado'}
                >
                  <EditEqListado equipoInfo={equipoInfo} setEquipos={setEquipos} equipos={equipos} setEstadomodalEd={setEstadomodalEd}/>
                </Modales>
            <table  className='table w-100 table-bordered '>
              <thead className="cabeceraitem">
                <tr>
                        <th>Item</th>
                        <th >Marca</th>
                        <th >Modelo</th>
                        <th >Serie</th>
                        <th >Inventario</th>
                        <th >Ubicación</th>
                        <th >Resolución</th>
                        <th >Puntos a calibrar</th>
                        <th><i className="bi bi-pencil-square font_sz" ></i></th>
                        <th><i className="bi bi-trash font_sz"/></th>
                  </tr>
                </thead>
               <tbody>
          {equipos &&equipos.map((eq,index)=>(
            <tr key={index}>
              <td>{eq.nombreitem}</td>
              <td>{eq.marca}</td>
              <td>{eq.modelo}</td>
              <td>{eq.serie}</td>
              <td>{eq.inventario}</td>
              <td /*className="ubicacioneq"*/>{eq.ubicacion}
                   {/*<span className="toolti-box">{eq.ubicacion}</span>*/}
              </td>
              <td>{eq.resolucion}</td>
              <td>{eq.puntos}</td>
              <td>{eq._id? <></>:<i className="bi bi-pencil-square cursor-p font_sz"  onClick={()=>Editequipo(index)} ></i>}</td>
              <td onClick={()=>{del(index)}}><label className="bi bi-trash cursor-p font_sz"></label></td>
            </tr>
          ))}
          </tbody>
          </table>
        </div>

    )
}

export default AddedEquipments;