import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { consultarLoteEq, getDataList, postDataClientes, postDataEquiposlotes, postDataOt} from '../api/common';
import Select from "react-dropdown-select";
import jwt_token from 'jwt-decode';
import Swal from 'sweetalert2'
import { Historial } from '../components/Crearot/Historial';
import { Modalcarga } from '../components/Modales/Modalcarga';
import Spinner from '../components/commons/Spinner';
import { Alertassw } from '../components/Alertas/Alertassw';
import FormEquipments from '../components/Equipo/FormEquipments';
import HeaderEquipments from '../components/Equipo/HeaderEquipments';
import AddedEquipments from '../components/Equipo/AddedEquipments';
import { Modales } from '../components/Modales'
import { Upload } from '../components/Uplaodexcel/Upload';
import { useNavigate } from 'react-router-dom';
import AddEquipments from '../components/Ot/AddEquipments';
import { useTheme } from '../hooks/useTheme';
import AddSede from '../components/Ot/AddSede';
import EquiposRep from '../components/Ot/EquiposRep';
import { VerifiedSerialList } from '../components/Ot/VerifiedSerialList';

let errors=[]
export const  CrearOt = () => {
  const navigate = useNavigate();
  const[clienteselect, setClienteselect]=useState(false)
  const[sedeOn, setSedeOn]=useState(true) //Estado para quitar el select
  const[estadomodal, setEstadomodal]=useState(false)
  const {size} = useTheme()
  const[estadomodalIn, setEstadomodalIn] = useState(false);
  const[modalEqExist, setModalEqExist] = useState(false);
  const[modalAddEquipment, setModalAddEquipment] = useState(false);
  const[modalAddSede, setModalAddSede] = useState(false);
  const [pestaña, setPestaña]=useState(false)
  const [refresh, setRefresh]=useState(false)
  const [sedesdata, setSedesdata]=useState()
  //estado para almacenar los datos del usuario en sesion
  const [userData, setUserData] = useState({});
  //almacena los datos de los metrologos
  //const[metrologos, setMetrologos]=useState([])
  //estado que almacena la lista de los items/equipos
  const [items, setItems]= useState([])
  // creamos el esatado para el equipo a guardar
  const [equipo, setEquipo]=useState(
    {
      cliente:null,
      sede:'Principal',
      item:'',
      marca:'',
      modelo:'No Presenta',
      serie:'',
      inventario:'No Presenta',
      ubicacion:'No Informa',
      resolucion:'No Informa',
      puntos:'No Informa',
      observacion:'',
      nombreitem:''     //verificar que reciba en la base de dato 
    }
  )

  // Creamos el estado para la lista de equipos 
  const [equipos, setEquipos]=useState([])
  const [selectCliente, setUser] = useState([]);        // Este estado almacena todos los datos del cleinte seleccionado
  //console.log(selectCliente)
  const [listClient, setClient] = useState([
    {value:"Agregar",
     label:"142353"}
  ]);         // estado que me permite mostrar la lista de clientes
  const [listSede, setListSede]=useState([]);
  //console.log(listSede)
  const [sedeSelect, setSedeSelect]=useState([])
  //console.log('Sede seleccionada : ',sedeSelect)

  //funcion para Cargar // este es el estado para guardar todos los clientes 
  const [clientes, setClientes] = useState([]);          // este estado alamcena todos los ususarios registrados en la base de datosla lista de equipos
  
  const clickot= ()=>{
    const buttonSubmit = document.getElementById("submit-ot");
    buttonSubmit.disabled = true
  if(ot.numeroOrden){
    if(equipos.length>0 ){ 
    postItem();
    }else{ Alertassw('error',"Error al cargar", "Por favor agregar items a la lista")}
  }else{Alertassw('error',"Error Crear Ot", "Por favor asignar número de propuesta")}
  buttonSubmit.disabled = false
  }
  
  // client representa el filtro a realizar solicita que se devuelva todos los usuarios con rol clientes
    const client = {
      "role.nombre":"Cliente",
      "type":"foreign",
       "populate":[]
  }
  const [_id, setItemsdata]=useState({
    cliente:{
      _id:''
    }
  })
  // se crea el body de la Ot
  const [ot, setOt]=useState({
    cliente:'',
    equipos:{
      equipo:''
    },
    numeroOrden:null,
    responsable:null,
    sede:"Principal",
    fechaInicio:'',
    coordinador:null,
    observacion:'',
  })
  //eveto para agregar informacion al body de la OT
 const HandledataOT =(event)=>{
      setOt({
        ...ot,
        [event.target.name]:event.target.value
      })
      //console.log(ot)
 }
    //creamos el evento cuando seleccionamos el item
    const selectitem =(value) => {
      //console.log(ot)
      if (value.length>0){     // pregunto si esta vacio
         // //console.log(value[0].label)
          setEquipo({     // aqui almaceno el Id del item seleccionado en el nombre del equipo 
            ...equipo,
            item:value[0].value,
            nombreitem:value[0].label
          })
          //console.log(equipo)
    } else {setEquipo({
      ...equipo,
      item:''
    })}
  }
    //evento para capturar el formulario del equipo
    const handledata = (event) =>{
      setEquipo({
        ...equipo,
        [event.target.name]:event.target.value
      })
      //console.log(equipo)
    }  
  // evento para guardar listado de equipos y crear la ot
  async function postItem(){
    try {
      const eq = await postDataEquiposlotes(equipos)
      if(eq.status===200 )
      {
        ot.equipos=eq.data.equipos
        console.log(ot)
        postOt();
        Alertassw('charge','Equipos cargados Creando OT')
      }
    } catch (error) {
      console.log(error)
      
      if(error.response.data.error.message==='EQUIPOS EXISTENTES'){
        errors=error.response.data.detail.split('|')
        console.log(errors)
        setModalEqExist(true) 
      }
    }
   }
    //Creamos la OT con los equipos registrados
    async function postOt(){
    if(ot.numeroOrden){
      try{
        setEstadomodalIn(true)
        const resultOt = await postDataOt(ot)
          setEstadomodalIn(false)
          cleardatacliente()
          Alertassw('ok',"Has Creado la Orden"+" "+ot.numeroOrden)
        setRefresh(!refresh)
      }catch(error){
        console.log(error.response.data)
        setEstadomodalIn(false)
        if(error.response.data.detail.includes("existente"))setModalAddEquipment(true)
        else Alertassw('error', 'Creando Orden',error.response.data.error.message)
      }
 } else{
  Swal.fire({
    title:"No. Propuesta no ingresado",
    icon:"error",
    button:"Ok"
})
 }
}   

// funcion para limpiar la data del cliente
function cleardatacliente(){
  setPestaña(false) 
  setClienteselect(false)
  setEquipos([])
  setSedeSelect([])
  setListSede([])
  setOt([])
  setUser([])
    setItemsdata({
      _id:''
     })
    setEquipo({  
    ...equipo,
    cliente:'',
    sede:'Principal',
    sedeClient:''
  })
}

  // evento para borrar equipos de la lista
  const del = (index)=>{
    //console.log(index)
    equipos.splice(index,1)
    setEquipos([
      ...equipos
    ])
  }
  //Funcion para agregar equipo a la lista 
  const add = async()=>{

      if(equipo.cliente && equipo.item && equipo.marca && equipo.modelo ){
        let verificar=VerifiedSerialList(equipos,equipo)
        if(verificar.length===0){
        try {
          const verficarEq=await consultarLoteEq('equipos',[equipo])
          setEquipos([
            ...equipos, 
            equipo
          ])
        } catch (error) {
          if(error.response.data.error.message==='EQUIPOS EXISTENTES'){
            errors=error.response.data.detail.split('|')
            console.log(errors)
            setModalEqExist(true) 
          }
        }
      }
    }else{Swal.fire({
      title:"Error Al agregar",
      text: "Se requiere informacion completa del equipo y/o Cliente",
      icon:"warning",
      button:"Aceptar"
    }) }
  } 
  const historialtoggle=()=>{
    //console.log(_id._id)
    if(_id._id){
      setPestaña(true)
    }else{ Alertassw('error','Error','Selecciona un cliente')
    }
  }
  const nuevos =()=>{
    setPestaña(false)
  }

  // seleccionar la sede
  const selectSede = (value)=>{
    console.log(value)

    if(value[0].value==="Agregar"){
      setModalAddSede(true)
    }else{
          let resulta=sedesdata.filter(sede=> sede._id===value[0].value)
          setSedeSelect(resulta[0])
      changeSedeEq(value[0].value, value[0].label);
      setOt({
        ...ot,
        sedeClient:value[0].value,
        sede:value[0].label
      })
      setEquipo({  
        ...equipo,
        sedeClient:value[0].value,
        sede:value[0].label
      })
      setSedeOn(true)
    }
    
  }
  
  const changeSedeEq=(sede,label)=>{
    console.log("cambiando sede en equipos", sede);
    if(equipos.length>0){
      for(let i=0; i<equipos.length; i++){
        equipos[i].sedeClient=sede;
        equipos[i].sede=label;
      }
    }
  }


  // evento que se produce cuando seleccionamos el cliente
    const onDropdownchange = async(value) => { 
      setPestaña(false) 
       if (value.length>0){        // evento que ocurre cuando se selecciona el cliente con select
        const result = clientes.filter(word => word._id === value[0].value); //aplico filtro para obtener los datos del cliente seleccionado 
        setUser(result);          //Guardo el clliente seleccionado

        const sedesDB = await getDataList("Sedes",{cliente:result[0]._id});
        setSedesdata(sedesDB.data.data)                                              // Se guarda el listado de las sede
        let sedeDefault=sedesDB.data.data.filter(sede=> sede.nameSede==='Principal') // selecciona la sede principal por defecto
        setSedeSelect(sedeDefault[0])                                                // se guarda la sede seleccionada 
        let clientSede = sedesDB.data.data?.map((sed)=>({                           
          value:sed?._id,
          label:sed?.nameSede
      }))
        clientSede.push({
          value:'Agregar',
          label:'Agregar'
        })
        setListSede(clientSede)                                                     // guardo la lista desplegable de las sedes
        setClienteselect(true)
        setSedeOn(true)

       //agrego el id del cliente al cliente del equipo y de la ot.
       setOt({  
         ...ot,
         cliente:result[0]._id,
         sede:'Principal'
       })
       setItemsdata({
        _id:result[0]._id
       })
       setEquipo({  
         ...equipo,
         cliente:result[0]._id,
         sedeClient:sedeDefault[0]._id,
         sede:sedeDefault[0].nameSede,
       });

       ////console.log(result[0]._id)
    } else{
      cleardatacliente()
      }
  }

  //funcion para cargar equipos masivos con formato
  const chargeFile=()=>{
    //console.log(_id._id)
    if(_id._id){
      setEstadomodal(true)
    }else{ Alertassw('error','Error','Selecciona un cliente')
    }

  }
    useEffect(() => {
        async function postClientes(){
         const clientesDB = await postDataClientes(client);
         //const metrologos = await postDataClientes(dataUsers);
         //console.log(clientesDB.data.data)
          const itemDB = await getDataList('Items');
            ////console.log(clientesDB.data.data)
            const itemlist = itemDB.data.data.map((listitems)=>({
              value:listitems._id,
              label:listitems.nombre
            }))
         // //console.log(clientesDB.data.data)
           const cl = clientesDB.data.data.map((client)=>({
            value:client._id,
            label:client.nombre
        }));
        setItems(itemlist)
        setClient(cl)
        setClientes([...clientesDB.data.data])
        }
        listClient.push({value:"Agregar Cliente", label:"12345"})
        async function getUserData(){
          const user = await jwt_token(localStorage.getItem('token'));
          setUserData(user)
          setOt({
            ...ot,
            coordinador:user._id
          })}
          getUserData()
        postClientes()
    },[])
    
  return (
    <div>   
      <Modales
        estado={estadomodal} 
        cambiarestado={setEstadomodal}
        titulo={'Cargar Formato excel'}
       >
        <Upload setPestaña={setPestaña} equipos={equipos} setEquipos={setEquipos} idcliente={_id} sede={ot.sede} sedeClient={sedeSelect} setEstadomodal={setEstadomodal}/>
      </Modales>
      <Modales 
       estado={modalAddEquipment} 
        cambiarestado={setModalAddEquipment}
        titulo={'Adicionar Equipo'}
      >
        <AddEquipments setEstadoModal={setEstadomodal} ot={ot}   setRefresh={setRefresh} refresh={refresh} eqInput={ot.equipos} setEstadomodalIn={setEstadomodalIn} />
      </Modales>
      <Modales 
       estado={modalEqExist} 
        cambiarestado={setModalEqExist}
        titulo={'Equipos Repetidos'}
      >
        <EquiposRep Equipos={errors} />
      </Modales>
            <Modales 
       estado={modalAddSede} 
        cambiarestado={setModalAddSede}
        titulo={'Agregar Sede'}
      >
        <AddSede setEstadoModal={setModalAddSede} Cliente={selectCliente[0]} setSedeOn={setSedeOn} listSede={listSede} setSedeSelect={setSedeSelect} setEstadomodalIn={setEstadomodalIn} selectSede={selectSede} sedesdata={sedesdata}/>
      </Modales>
      <Modalcarga 
        estadoIn={estadomodalIn} 
        cambiarestadoIn={setEstadomodalIn}
        >
          <Spinner />
      </Modalcarga>
        <div className='cabecera'>
        <label className='titulo'>Nueva Orden de trabajo</label>
      </div>
        <div className='datoot'>
            <div className='datauseri-client'>
              <label className='userselect' htmlFor='Email'>Cliente:</label>
              {clienteselect===false?(
                  <Select  searchable='true' onChange={onDropdownchange} options={listClient}/>):
                  <div className='client-ot'>
                  <label className="under_line"
                   onClick={() => navigate(`/perfil?correo=${selectCliente[0]?.correo}`)}
                   >
                  {selectCliente[0].nombre}</label>
                  <div><i className="bi bi-x-lg seach" onClick={()=>cleardatacliente()}></i></div>
                </div>
  }
            </div>
            <div className='datauseri' >
              <label className='userselect' htmlFor='contacto'>Contacto:</label>
              <label className='userselect cuadro' name='contacto'>{ sedeSelect?.contSede? sedeSelect?.contSede : selectCliente[0]?.contacto }</label>
            </div>
            <div className='datauseri_2'>
              <label className='userselect' htmlFor='numeroOrden'>No Propuesta:</label>
              <input onChange={HandledataOT} className='userselect' name = "numeroOrden" placeholder='No'/>
            </div>
            <div className='datauseri-client'>
              <label className='userselect' htmlFor='Email'>Sede:</label>
              {sedeOn===false?(
                  <Select  searchable='true' onChange={selectSede} options={listSede}/>):
                  <div className='client-ot'>
                  <label>
                  {ot.sede}</label>
                  <div><i className="bi bi-x-lg seach" onClick={()=>setSedeOn(false) }></i></div>
                </div>
              }
            </div>
        </div>
        <div className='datoot'>
        <textarea onChange={HandledataOT} className='observacionot'  name='observacion'  type={'string'}  placeholder='Observacion'></textarea>
        <button id="submit-ot" className='btn-btn-secundary' style={{'font-size': 18}} onClick={clickot}>Crear OT</button>
        </div>
        <div className='pestañas'>
          <div  className='pestaña '><label className={`textpestaña ${estadomodal===true? null: pestaña===true? null: 'pestañaActive'}`} onClick={nuevos}>Nuevos</label></div>
          <div  className='pestaña ' onClick={historialtoggle}><label className={`textpestaña ${pestaña===true && estadomodal===false? 'pestañaActive':null}`} >Historial</label></div>
          <div  className='pestaña ' onClick={chargeFile}><div  className={`textpestaña ${estadomodal===false? null: 'pestañaActive'}`}><label>Cargar archivo</label><i class="bi bi-file-arrow-up"></i></div></div>
        </div>
        <div className={`${size.width<800?"table_responsive":""}`}>   
          <div className={`${size.width<800?"fix-table":""}`}>
        {pestaña=== true?(
          <Historial pestaña={setPestaña} cliente={_id} addequipos={equipos}  listarequipos={setEquipos} sede={sedeSelect.nameSede } setSedeSelect={setSedeSelect}/>):(
        <div>
        {/*<HeaderEquipments />*/}
        <AddedEquipments setEquipos={setEquipos} equipos={equipos} del={del} selectitem={selectitem} handledata={handledata}  add={add} />
        <hr></hr>
        <FormEquipments selectitem={selectitem} handledata={handledata}  add={add}  />    
        <hr/>
        <br/>       
        </div> )}
        </div> 
        </div>               
    </div> 
  )
}
