import React from 'react'

export const IndicadoresOt = (equipos) => {
  console.log(equipos);
if(equipos){
        // Inicializamos contadores para cada estado
        let contadorNoFinalizado = 0;
        let contadorFinalizado = 0;
        let contadorPendiente = 0;
        let contadorCancelado = 0;
        let contadorProcesado = 0;
        let contadorAprobado = 0;
        let totalEquipos = equipos.length;
    
        // Iteramos sobre cada equipo
        equipos.forEach((equipo) => {
            // Incrementamos el contador correspondiente al estado del equipo
            switch (equipo.estado) {
                case "Aprobado":
                    contadorAprobado++;
                    break;
                case "Pendiente":
                    contadorPendiente++;
                    break;
                case "Finalizado":
                    contadorFinalizado++;
                    break;
                case "No Finalizado":
                    contadorNoFinalizado++;
                    break;
                case "Cancelado":
                    contadorCancelado++;
                    break;
                case "Procesado":
                    contadorProcesado++;
                    break;
                default:
                    break;
            }
        });
    
        // Calculamos los porcentajes para cada estado
        const porcentajeNoFinalizado = Math.round((contadorNoFinalizado / totalEquipos) * 100);
        const porcentajeFinalizado = Math.round((contadorFinalizado / totalEquipos) * 100);
        const porcentajePendiente = Math.round((contadorPendiente / totalEquipos) * 100);
        const porcentajeCancelado = Math.round((contadorCancelado / totalEquipos) * 100);
        const porcentajeProcesado = Math.round((contadorProcesado / totalEquipos) * 100);
        const porcentajeAprobado = Math.round((contadorAprobado / totalEquipos) * 100);
    
        // Creamos el objeto de indicador con los porcentajes
        const indicador = {
            "No_finalizados": porcentajeNoFinalizado,
            "Finalizados": porcentajeFinalizado,
            "Pendientes": porcentajePendiente,
            "Cancelados": porcentajeCancelado,
            "Procesados": porcentajeProcesado,
            "Aprobados": porcentajeAprobado,
        };
    
        return (indicador)
        // Establecemos el estado con el indicador calculado

    }
  } 

  
  export const ContadoresOt = ({ots}) => {
    console.log(ots)
    let itemsList=[]
    let gestionados=[]
    if(ots?.equipos){
        itemsList= contarEquipos(ots?.equipos)
        gestionados= ots?.equipos?.filter(equipo => equipo.estado!=='No Finalizado')
    }

    return (
      <div>
         <div className="container-no-padding">
          <div className="row">
            {/*Columna Izquierda */}
          <div className="col-md-2 border-end  shadow p-3">
            <h5 className="mb-3">Ítems Totales</h5>
            <hr/>
            <div className="mb-0 d-flex align-items-center">
                <label htmlFor="total" className="form-label fw-bold me-2 m-0">Cargados:</label>
                <p className="mb-0" style={{overflowWrap:'break-word'}}>{ots?.equipos?.length}</p>
            </div>
            <div className="mb-0 d-flex align-items-center">
                <label htmlFor="contactoEmail" className="form-label fw-bold me-2 m-0">Gestionados:</label>
                <p className="mb-0" style={{overflowWrap:'break-word'}}>{gestionados.length}</p>
            </div>

          </div>
          {/* Columna Derecha */}
          <div className="col-md-10 border-start shadow p-3 ">
          <div className="row ">
            {itemsList && itemsList?.map((item, index)=>(
            <div key={index} className='container p-1 mb-1' style={{maxWidth:'160px'}}>
                <div className='row border align-items-start bg-primary ' style={{ minHeight: '3rem' }} >
                    <div className='col text-center justify-content-center'>
                        <p className='small mb-0' style={{overflowWrap:'break-word'}}><strong>{item?.nombre}</strong></p>
                    </div>
                </div>
                <div className='row border  align-items-start'>
                    <div className='col border text-center'>
                        <p className='small mb-0'> <strong>T:</strong> { item?.total}</p>
                    </div>
                    <div className='col border text-center'>
                        <p className='small mb-0'><strong>G:</strong> {item?.finalizados}</p>
                    </div>
                </div>
            </div>
            ))}
            </div>
          </div>
        </div>
    </div>

        
      </div>
    )
  }

  export function contarEquipos(equipos){
    // Array para almacenar los resultados agrupados
    const resultadosArray = [];

    // Función para buscar un elemento en el array de resultados por nombre de equipo
    const findIndexByName = (array, name) => {
        for (let i = 0; i < array.length; i++) {
            if (array[i].nombre === name) {
                return i;
            }
        }
        return -1;
    };

    // Iterar sobre el array de equipos
    equipos.forEach(equipo => {
        const nombreEquipo = equipo.equipo.item.nombre;
        const estado = equipo.estado;
        const responsable = equipo.responsable ? equipo.responsable.nombre + ' ' + equipo.responsable.apellido : '';

        // Buscar si el equipo ya existe en el array de resultados
        const index = findIndexByName(resultadosArray, nombreEquipo);

        // Si el equipo no existe en el array de resultados, lo agregamos
        if (index === -1) {
            resultadosArray.push({
                nombre: nombreEquipo,
                total: 1,
                finalizados: estado !== 'No Finalizado' ? 1 : 0,
                noFinalizados: estado === 'No Finalizado' ? 1 : 0,
                responsables: {}
            });
        } else {
            // Si el equipo ya existe, actualizamos los contadores correspondientes
            resultadosArray[index].total++;
            if (estado !== 'No Finalizado') {
                resultadosArray[index].finalizados++;
            } else {
                resultadosArray[index].noFinalizados++;
            }
        }

        // Incrementar el contador de equipos manejados por cada responsable
        const resultIndex = findIndexByName(resultadosArray, nombreEquipo);
        if (resultIndex !== -1) {
            if (!resultadosArray[resultIndex].responsables[responsable]) {
                resultadosArray[resultIndex].responsables[responsable] = 0;
            }
            resultadosArray[resultIndex].responsables[responsable]++;
        }
    });

    resultadosArray.sort((a, b) => a.nombre.length - b.nombre.length);
    return resultadosArray;
}
  
  


