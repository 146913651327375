import React, { useState, useEffect } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { actualizarDatos, getDataList, getDataObtenerUno } from '../../api/common';
import moment from 'moment';
import { Alertassw } from '../Alertas/Alertassw';
import { deleteFile, saveFile } from '../../helpers/firebase';
import { Modalcarga } from '../Modales/Modalcarga';
import { OtrosDocAdjuntos } from './OtrosDocAdjuntos';


const PerfilPatron = () => {
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const [editable, setEditable] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [dataOriginal, setDataoriginal] = useState();
    const [dataPatron, setDataPatron]=useState();
    const [dataActividades, setDataActividades]=useState();
    const [estadomodalIn, setEstadomodalIn]=useState(false)
    const [refresh, setRefresh]=useState(false)
    //Estados para modal de fotos
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const [showModalDoc, setShowModalDoc] = useState(false);

    const handleShow = () => setShowModalDoc(true);
    const handleClose = () => setShowModalDoc(false);

    const handleImageClick = (index) => {
      setSelectedImageIndex(index === selectedImageIndex ? null : index);
    };

   // console.log('Imagenes Equipo Patrón', dataPatron)
    //console.log('Data Patron Original', dataOriginal)

    const editData=(e)=>{
        setDataPatron(
            {
                ...dataPatron,
                [e.target.name]: e.target.value
            }
        )
    }

    const uploadFoto =async(event)=>{
        console.log(event.target?.files[0].type)
        if(event.target?.files[0].type==='image/png' || event.target?.files[0].type==='image/jpeg' ){
            try{
                setEstadomodalIn(true)
                const link = await saveFile(event.target,'images')
                if(link){
                  Alertassw('charge','Archivo caragdo correctamente')
                  const actualizarPatron = await actualizarDatos('Patrones',{...dataPatron,
                    foto:link
                },dataPatron._id)
                  setDataPatron({
                    ...dataPatron,
                    foto:link
                  })
                  setEstadomodalIn(false)
                }
            }catch(error){
              console.log(error)
            }
          }else{
              Alertassw('error','Error de formato de imagen','Sube una foto con formato png o jpg')
          }
    }

    const deleteImage = async(img) =>{
        console.log(img)
        try {
        setEstadomodalIn(true)
        await deleteFile(img) 
        setDataPatron({
            ...dataPatron,
            foto: null
        })   
        Alertassw('charge','Foto Eliminada Correctamente')
        setEstadomodalIn(false)  
        } catch (error) {
            
        }
  
      }   
    useEffect(() => {
        async function consultarData() {
            const patronData = await getDataObtenerUno('Patrones', { _id: id });
            let respuesta = await getDataList('Actividad',{ equipo: id });
            setDataPatron(patronData?.data?.data)
            setDataoriginal(patronData?.data?.data)
            setDataActividades(respuesta?.data?.data[0])
        }

        consultarData();
    }, [id,refresh]);

    const handleEditClick = () => {
        setEditable(!editable);
    };

    const handleSaveClick =async () => {
        setEditable(false);
        try {
            const changes = getEditedFields(dataOriginal, dataPatron);
            console.log("cambios realizados",changes)
            setEstadomodalIn(true);
            const actualizarPatron = await actualizarDatos('Patrones',{...changes,
                
            },dataOriginal._id)
            console.log(actualizarPatron)
            setEstadomodalIn(false);
            setRefresh(!refresh);
            
        } catch (error) {
            console.log(error)
        }

        // Add logic to save data here
    };

    const getEditedFields = (original, edited) => {
        const editedFields = {};
      
        for (const key in edited) {
          if (edited[key] !== original[key]) {
            editedFields[key] = edited[key];
          }
        }
        // Asegurarse de incluir el campo _id si está presente en ambos objetos
        if (original._id) {
            editedFields._id = original._id;
        }
        return editedFields;
      };

    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    //Sección para modal de fotos
    const openModal = () => {
        setModalIsOpen(true);
      };
    
      const closeModal = () => {
        setModalIsOpen(false);
      };
    
      const handleImageChange = async(e) => {
        const files = Array.from(e.target.files);
        const validImages = files.filter(file =>
          file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
        );
        if (validImages.length > 0) {
            try {
                const links = await Promise.all(validImages.map(async (file) => {
                    // Crear un evento simulado con el archivo como target
                    const event = {
                        target: {
                          files: [file]
                        }
                      };
                      const link = await saveFile(event.target,'images'); // Enviar el evento simulado a saveFile
                      return link;
                }));
                Alertassw('charge','Archivo caragdo correctamente')
                console.log(links)
                const actualizarPatron = await actualizarDatos('Patrones',{...dataPatron,
                  fotos:[...dataPatron.fotos, ...links]
              },dataPatron._id)
              console.log(actualizarPatron)
                setDataPatron({
                    ...dataPatron,
                    fotos: [...dataPatron.fotos, ...links]
                });
              } catch (error) {
                console.error('Error saving files:', error);
              }

        } else {
          alert('Please select valid image files (JPEG, PNG, GIF)');
        }
      };

      const deleteImageArray = async(img) =>{
        try {
            await deleteFile(img)
            Alertassw('charge','Archivo eliminado correctamente')
            const nuevasFotos = dataPatron?.fotos.filter(foto => foto !== img);
            console.log("Nuevo array",nuevasFotos )
            const actualizarPatron = await actualizarDatos('Patrones',{...dataPatron,
                  fotos:[...nuevasFotos]
              },dataPatron._id)
              console.log(actualizarPatron)
                setDataPatron({
                    ...dataPatron,
                    fotos: [...nuevasFotos]
                });
            
        } catch (error) {
            
        }

      
      }    

    const handleAddImages = () => {
        const event = {
          target: {
            name: 'fotos',
            value: selectedImages,
          },
        };
        editData(event);
        closeModal();
      };

    return (
        <div className="perfil-patron-container">
            <OtrosDocAdjuntos  show={showModalDoc} handleClose={handleClose} dataPatron={dataPatron}/>
            <Button onClick={editable ? handleSaveClick : handleEditClick}>
                {editable ? 'Guardar' : 'Editar'}
            </Button>
            <table className="custom-table">
                <thead>
                    <tr><th colSpan={9} className="table-header">SISTEMA INTEGRADO DE GESTIÓN</th></tr>
                </thead>
                <tbody>
                    <tr><td rowSpan={3}>imagen</td></tr>
                    <tr>
                        <td><strong>Proceso:</strong> Mantenimiento e infraestrutura</td>
                        <td><strong>Código:</strong>APO-MTO-FOR-002</td>
                    </tr>
                    <tr>
                        <td><strong>Formato:</strong> Hoja de vida Equipo</td>
                        <td><strong>Versión:</strong> 03</td>
                    </tr>
                </tbody>
            </table>

            <table className="custom-table ">
                <tbody>
                    <tr>
                        <th className="table-header">ÚLTIMA ACTUALIZACIÓN</th>
                        <td>2023-07-23</td>
                        <td className='espacioBlanco'></td>
                        <th className="table-header">ESTADO</th>
                        <td>ACTIVO</td>
                        <td className='espacioBlanco'></td>
                        <th className="table-header">PROPIEDAD DEL EQUIPO</th>
                        <td>{editable ? <select type="text" name='propiedad' onChange={editData} className='w-100' defaultValue={dataPatron?.propiedad}>
                            <option value="Propio">Propio</option>
                            <option value="Arriendo">Arriendo</option>
                            <option value="Comodato">Comodato</option>
                            <option value="Prestamo">Prestamo</option>
                        </select> : dataPatron?.propiedad}</td>

                    </tr>
                </tbody>
            </table>
            <table className="custom-table ">
                <thead>
                    <tr>
                        <th colSpan={5} className="table-header">INFORMACIÓN DEL EQUIPO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th colSpan={2}>IDENTIFICACIÓN DEL EQUIPO:</th>
                        <th colSpan={2}>PARÁMETROS DE TRABAJO</th>
                        <td rowSpan={7}><img className="img_patron_hv" src={dataPatron?.foto} alt="img" /></td>
                    </tr>
                    <tr>
                        <th>INSTRUMENTO/EQUIPO:</th>
                        <td>{editable ? <input onChange={editData} name='item' type="text" value={dataPatron?.item} /> : dataPatron?.item}</td>
                        <th>DIVISIÓN DE ESCALA/RESOLUCIÓN:</th>
                        <td>{editable ? <input onChange={editData} name='resolucion' type="text" value={dataPatron?.resolucion} /> : dataPatron?.resolucion}</td>
                    </tr>
                    <tr>
                        <th>MARCA:</th>
                        <td>{editable ? <input onChange={editData} name='marca' type="text" value={dataPatron?.marca} /> : dataPatron?.marca}</td>
                        <th>MAGNITUD:</th>
                        <td>{editable ? <input onChange={editData} name='magnitud' type="text" value={dataPatron?.magnitud} /> : dataPatron?.magnitud}</td>
                    </tr>
                    <tr>
                        <th>MODELO:</th>
                        <td>{editable ? <input onChange={editData} name='modelo' type="text" value={dataPatron?.modelo} /> : dataPatron?.modelo}</td>
                        <th>UNIDAD DE MEDIDA:</th>
                        <td>{editable ? <input onChange={editData} name="unidades" type="text" value={dataPatron?.unidades} /> : dataPatron?.unidades}</td>
                    </tr>
                    <tr>
                        <th>SERIE:</th>
                        <td>{editable ? <input onChange={editData} name='serie' type="text" value={dataPatron?.serie} /> : dataPatron?.serie}</td>
                        <th>MEDIO DE TRABAJO:</th>
                        <td>{editable ? <input onChange={editData} name='medioTrabajo' type="text" value={dataPatron?.medioTrabajo}  /> : dataPatron?.medioTrabajo}</td>
                    </tr>
                    <tr>
                        <th>INVENTARIO:</th>
                        <td>{editable ? <input onChange={editData} name='inventario' type="text" value={dataPatron?.inventario} /> : dataPatron?.inventario}</td>
                        <th>EXACTITUD:</th>
                        <td>{editable ? <input onChange={editData} name='exactitud' type="text" value={dataPatron?.exactitud} /> : dataPatron?.exactitud}</td>
                    </tr>
                    <tr>
                        <th>FECHA DE ADQUISICIÓN:</th>
                        <td>{editable ? <input onChange={editData} name='fechaAdquisicion' type="Date" className='w-100' value={moment(dataPatron?.fechaAdquisicion).format('YYYY-MM-DD')} /> : dataPatron?.fechaAdquisicion? moment(dataPatron?.fechaAdquisicion).format("L"): "No Disponible" }</td>
                        <th>INTERVALO DE TRABAJO:</th>
                        <td>{editable ? <input onChange={editData} name='intervaloTrabajo' type="text" value={dataPatron?.intervaloTrabajo} /> : dataPatron?.intervaloTrabajo}</td>
                    </tr>
                    <tr>
                        <th>FECHA OPERACIÓN:</th>
                        <td>{editable ? <input onChange={editData} name='fechaOperacion' type="Date" className='w-100' value={moment(dataPatron?.fechaOperacion).format('YYYY-MM-DD')} /> : dataPatron?.fechaOperacion? moment(dataPatron?.fechaOperacion).format("L"): "No Disponible" }</td>
                        <th>CLASIFICACIÓN ELÉCTRICA:</th>
                        <td>{editable ? <select type="text" name='clasElectrica' onChange={editData} className='w-100' value={dataPatron?.clasElectrica}>
                            <option value="No Aplica">No Aplica</option>
                            <option value="B">B</option>
                            <option value="BF">BF</option>
                            <option value="CF">CF</option>
                        </select> :dataPatron?.clasElectrica}
                        </td>
                        <td>
                            {editable ?<Button variant="primary" onClick={handleModalShow}>
                                Actualizar
                            </Button>:null}
                        </td>
                    </tr>
                </tbody>
            </table>

            <table className="custom-table">
                <thead>
                    <tr>
                        <th colSpan={6} className="table-header">UBICACIÓN</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>EMPRESA:</th>
                        <td>{editable ? <input onChange={editData} name='empresa' type="text" defaultValue="BIOLAB LABORATORIO METROLOGICO SAS" /> : 'BIOLAB LABORATORIO METROLOGICO SAS'}</td>
                        <th>CIUDAD:</th>
                        <td>{editable ? <input onChange={editData} name='' type="text" defaultValue="CERETÉ" /> : 'CERETÉ'}</td>
                        <th>DEPARTAMENTO:</th>
                        <td>{editable ? <input onChange={editData} name='' type="text" defaultValue="CORDOBA" /> : 'CORDOBA'}</td>
                    </tr>
                    <tr>
                        <th>DIRECCIÓN:</th>
                        <td>{editable ? <input onChange={editData} name='dirección'type="text" defaultValue="CALLE 9#16-09" /> : 'CALLE 9#16-09'}</td>
                        <th>TELEFONO:</th>
                        <td>{editable ? <input onChange={editData} name='telefono' type="text" defaultValue="3287782773" /> : '3287782773'}</td>
                        <th>AREA:</th>
                        <td>{editable ? <input onChange={editData} name='area' type="text" defaultValue="LABORATORIO DE PRESIÓN" /> : 'LABORATORIO DE PRESIÓN'}</td>
                    </tr>
                </tbody>
            </table>

            <table className="custom-table">
                <thead>
                    <tr>
                        <th colSpan={8} className="table-header">INFORMACIÓN DEL PROVEDOR</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>FABRICANTE:</th>
                        <td>{editable ? <input onChange={editData} name='fabricante' type="text" value={dataPatron?.fabricante} /> : dataPatron?.fabricante}</td>
                        <th>PROVEEDOR:</th>
                        <td>{editable ? <input onChange={editData} name='proveedor' type="text" value={dataPatron?.proveedor} /> : dataPatron?.proveedor}</td>
                        <th>PAIS:</th>
                        <td>{editable ? <input onChange={editData} name='paisProveedor' type="text" value={dataPatron?.paisProveedor} /> : dataPatron?.paisProveedor}</td>
                        <th>CIUDAD:</th>
                        <td>{editable ? <input onChange={editData} name='ciudadProveedor' type="text" value={dataPatron?.ciudadProveedor} /> : dataPatron?.ciudadProveedor}</td>
                    </tr>
                    <tr>
                        <th>GARANTÍA:</th>
                        <td>{editable ? <input onChange={editData} name='garantia' type="text" value={dataPatron?.garantia} /> : dataPatron?.garantia}</td>
                        <th>DIRECCIÓN:</th>
                        <td>{editable ? <input onChange={editData} name='dirProveedor' type="text" value={dataPatron?.dirProveedor} /> : dataPatron?.dirProveedor}</td>
                        <th>TELEFONO:</th>
                        <td>{editable ? <input onChange={editData} name='telefonoProveedor' type="text" value={dataPatron?.telefonoProveedor} /> : dataPatron?.telefonoProveedor}</td>
                        <th>REPRESENTANTE:</th>
                        <td>{editable ? <input onChange={editData} name='representanteProveedor' type="text" value={dataPatron?.representanteProveedor} /> : dataPatron?.representanteProveedor}</td>
                    </tr>
                </tbody>
            </table>
            <table className="custom-table ">
                <thead>
                    <tr>
                        <th colSpan={10} className="table-header">INFORMACIÓN TÉCNICA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>VOLTAJE:</th>
                        <td>{editable ? <input onChange={editData} name='voltaje' type="text" value={dataPatron?.voltaje} /> : dataPatron?.voltaje}</td>
                        <th>CORRIENTE:</th>
                        <td>{editable ? <input onChange={editData} name='corriente' type="text" value={dataPatron?.corriente} /> : dataPatron?.corriente}</td>
                        <th>POTENCIA:</th>
                        <td>{editable ? <input onChange={editData} name='potencia' type="text" value={dataPatron?.potencia} /> : dataPatron?.potencia}</td>
                        <th>FUENTE ALIMENTACION:</th>
                        <td>{editable ? <input onChange={editData} name='fuenteAlimentacion' type="text" value={dataPatron?.fuenteAlimentacion} /> : dataPatron?.fuenteAlimentacion}</td>
                        <th>SOFTWARE:</th>
                        <td>{editable ? <input onChange={editData} name='vSoftware' type="text" value={dataPatron?.vSoftware} /> : dataPatron?.vSoftware}</td>
                    </tr>
                    <tr>
                        <th>FRECUENCIA:</th>
                        <td>{editable ? <input onChange={editData} name='frecuencia' type="text" value={dataPatron?.frecuencia} /> : dataPatron?.frecuencia}</td>
                        <th>PESO:</th>
                        <td>{editable ? <input onChange={editData} name='peso' type="text" value={dataPatron?.peso} /> : dataPatron?.peso}</td>
                        <th>DIMENSIONES:</th>
                        <td>{editable ? <input onChange={editData} name='dimensiones' type="text" value={dataPatron?.dimensiones} /> : dataPatron?.dimensiones}</td>
                        <th>CONDICIONES AMBIENTALES:</th>
                        <td>{editable ? <input onChange={editData} name='condicionesAmbientales' type="text" value={dataPatron?.condicionesAmbientales} /> : dataPatron?.condicionesAmbientales }</td>
                        <th>FIRMWARE:</th>
                        <td>{editable ? <input onChange={editData} name='vFirmware' type="text" value={dataPatron?.vFirmware} /> : dataPatron?.vFirmware}</td>
                    </tr>
                </tbody>
            </table>

            <table className="custom-table ">
                <thead>
                    <tr>
                        <th colSpan={10} className="table-header">INFORMACIÓN DOCUMENTAL</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>MANUAL DE USUARIO:</th>
                        <td>{editable ? <input onChange={editData} type="text" defaultValue="NO DISPONIBLE" /> : 'NO DISPONIBLE'}</td>
                        <th>MANUAL DE SERVICIO:</th>
                        <td>{editable ? <input onChange={editData} type="text" defaultValue="VER" /> : 'VER'}</td>
                        <th>MANUAL DE MANTENIMIENTO:</th>
                        <td>{editable ? <input onChange={editData} type="text" defaultValue="NO DISPONIBLE" /> : 'NO DISPONIBLE'}</td>
                    </tr>
                    <tr>
                        <th>NÚMERO DE FACTURA:</th>
                        <td>{editable ? <input onChange={editData} name='numeroFactura' type="text" value={dataPatron?.numeroFactura} /> : dataPatron?.numeroFactura}</td>
                        <th>CERTIFICADO DE IMPORTACIÓN:</th>
                        <td>{editable ? <input onChange={editData} type="text" defaultValue="VER" /> : 'VER'}</td>
                        <th>REGISTRO INVIMA: </th>
                        <td>{editable ? <input onChange={editData} type="text" name='regInvima' value={dataPatron?.regInvima} /> : dataPatron?.regInvima}</td>
                    </tr>
                </tbody>
            </table>

            <table className="custom-table">
                <thead>
                    <tr>
                        <th colSpan={2} className="table-header">DESCRIPCIÓN Y APLICACIÓN DEL EQUIPO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{editable ? <textarea name='aplicacionEquipo' className="w-100" onChange={editData} value={dataPatron?.aplicacionEquipo} /> : <p>{dataPatron?.aplicacionEquipo}</p>}</td>
                    </tr>
                </tbody>
            </table>

            <table className="custom-table ">
                <thead>
                    <tr>
                        <th colSpan={2}  className="table-header">ESPECIFICACIONES ADICIONALES Y ACCESORIOS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>{editable ? <textarea name='especifAccesorios' className="w-100" onChange={editData}  value={dataPatron?.especifAccesorios} /> : <p>{dataPatron?.especifAccesorios}</p>}</td>
                    </tr>
                </tbody>
            </table>
            <table className="custom-table ">
                <thead>
                    <tr>
                        <th colSpan={5} className="table-header">PROTOCOLO DE MANTENIMIENTO PREVENTIVO Y CALIBRACIONES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>DESCRIPCIÓN</th>
                        <td className='espacioBlanco'></td>
                        <th>APLICACIÓN</th>
                        <th>RESPONSABLE</th>
                        <th>FRECUENCIA</th>
                    </tr>
                {dataPatron? dataPatron?.protocoloMtto?.map((protocolo,index)=>(
                    <tr key={index}>
                        <td>{protocolo?.actividad?.toUpperCase()}</td>
                        <td className='espacioBlanco'></td>
                        <td>{protocolo?.aplicacion}</td>
                        <td>{protocolo?.responsable}</td>
                        <td>{protocolo?.frecuencia}</td>
                    </tr>
                )):null}
                <tr>
                    <td colSpan={5}><p>AO= Antes de operación, DO= Después de operación, DLO= Durante la Operación. / DT= Director Técnico, OP= Operario / 
                    D= Diario, S=Semanal, M= Mensual, T= Trimestral, SM= Semestral, A= Anual, N= Indeterminado</p></td></tr>
                </tbody>
            </table>
            <table className="custom-table ">
                <thead>
                    <tr>
                        <th colSpan={5} className="table-header">OTROS DOCUEMENTOS ADJUNTOS</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>ARCHIVO</th>
                        <th>VER</th>
                    </tr>
                    {dataPatron && dataPatron?.otrosDocuemntos?.map((documento,index)=>(
                    <tr key={index}>
                        <td></td>
                    </tr>
                    ))}
                    {editable? 
                        <span className='button-text' onClick={handleShow} >Add +</span>
                    :null}
                </tbody>
            </table>
            <table className="custom-table ">
                <thead>
                    <tr>
                        <th colSpan={5} className="table-header">FOTO DEL EQUIPO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td className="grid-container">
                        {dataPatron?.fotos.map((foto, index) => (
                        <div key={index} className="grid-item">
                            <img
                            src={foto}
                            alt={`Foto ${index + 1}`}
                            className={`grid-image ${index === selectedImageIndex ? 'large' : 'small'}`}
                            onClick={() => handleImageClick(index)}
                            />
                        </div>
                        ))}
                    </td>
                    </tr>
                    {editable?
                        <span className='button-text' onClick={openModal}>Add +</span>
                    :null}
                </tbody>
            </table>
            {/* Tabla para las actividades del equipo */}
            {dataActividades? Object.entries(dataActividades?.Actividades)?.map(([actividadKey, actividad], i) => (
            <table key={i} className="custom-table ">
                <thead>
                    <tr>
                        <th colSpan={7} className="table-header">{actividadKey?.toUpperCase()}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>FECHA</th>
                        <th>EJECUTOR</th>
                        <th>ESTADO</th>
                        <th>FRECUENCIA</th>
                        <th>FECHA PRÓXIMA ACT.</th>
                        <th>EVIDENCIA</th>
                        <th>CERTIFICADO</th>
                    </tr>
                    {actividad && actividad?.map((individual,index)=>(
                        <tr key={index}>
                            <td>{individual?.FechaInicio? moment(individual?.FechaInicio).format('L'):""}</td>
                            <td><strong>{individual?.responsable?.toUpperCase()}</strong></td>
                            <td>Operativo</td>  {/*individual?.estadoEquipo*/}
                            <td>{individual?.frecuencia} MESES</td>  {/*individual?.estadoEquipo*/}
                            <td>{individual?.FechaVencimiento? moment(individual?.FechaVencimiento).format('L'):""}</td>
                            <td>{individual?.consecutivo}</td>
                            <td><a href={individual?.evidencia} target="_blank" rel="noopener noreferrer">
                                VER CERTIFICADO
                            </a></td>
                        </tr>
                    ))}

                </tbody>
            </table>
         )):<></>}


            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Subir Foto del Equipo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {dataPatron?.foto?null:<input onChange={uploadFoto} type="file"/>}
                    {dataPatron?.foto?<div className='mt-5 pos-rel mr-3'>
                    <i onClick={()=>deleteImage(dataPatron?.foto)} className="bi bi-x-circle pos-abs right-0  close-x"></i>
                    <img className="img_patron" src={dataPatron?.foto} alt="img" />
                    </div>:null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>


            {/*Modal para fotos */}
            <Modal show={modalIsOpen} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Images</Modal.Title>
                </Modal.Header>
            <Modal.Body>
                <input
                    type="file"
               accept="image/*"
                    multiple
                    onChange={handleImageChange}
                />
                {dataPatron?.fotos && dataPatron?.fotos.map((img,index)=>
                    <div key={index} className='mt-5 pos-rel mr-3'>
                        <i onClick={()=>deleteImageArray(img)} class="bi bi-x-circle pos-abs right-0  close-x"></i>
                        <img className="img_form" src={img} alt="img" />
                    </div>)}
            </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Close</Button>
                    <Button variant="primary" onClick={handleAddImages}>Add Images</Button>
                </Modal.Footer>
            </Modal>     
            <Modalcarga
            estadoIn={estadomodalIn} cambiarestadoIn={setEstadomodalIn}
            >
                <Spinner/>
            </Modalcarga>
        </div>
    );
};

export default PerfilPatron;

