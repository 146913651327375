import { useEffect, useState } from "react";
import Select from "react-dropdown-select";
import { getDataList } from "../../api/common";
import SelectSearch from "../commons/SelectSearch";

const FormEquipments = ({ selectitem, handledata, add, cliente,role }) => {
  const [items, setItems] = useState(null);

  useEffect(() => {
    async function getItems() {
      const itemDB = await getDataList("Items");
      ////console.log(clientesDB.data.data)
      const itemlist = itemDB.data.data.map((listitems) => ({
        value: listitems._id,
        label: listitems.nombre,
      }));

      setItems(itemlist);
    }

    getItems();
  }, []);

  return (
    <>
      {items && (
        <form className={` formitem ${cliente?' formitem-11':' formitem-9'}`}>
          <Select
            onChange={selectitem}
            searchable="true"
            className="item1"
            options={items}
            required
          ></Select>
          {cliente ? (
            <SelectSearch
             className="h-100"
              handledata={handledata}
              name="cliente"
              collection={"Usuarios"}
              field={"nombre"}
              baseFilter={{ "role.nombre":"Cliente"}}
              disabled={role === 'Cliente'}
             
            />
          ) : null}
          <input
            onChange={handledata}
            name="marca"
            className="titulo"
            type={"string"}
            placeholder="Marca"
            required
          />
          <input
            onChange={handledata}
            name="modelo"
            className="titulo"
            type={"string"}
            placeholder="Modelo"
            required
          />
          <input
            onChange={handledata}
            name="serie"
            className="titulo"
            type={"string"}
            placeholder="Serie"
            required
          />
          <input
            onChange={handledata}
            name="inventario"
            className="titulo"
            type={"string"}
            placeholder="Inv"
          />
          <input
            onChange={handledata}
            name="ubicacion"
            className="titulo"
            type={"string"}
            placeholder="Ubicación"
          />
          <input
            onChange={handledata}
            name="resolucion"
            className="titulo"
            type={"string"}
            placeholder="Resolucion"
          />
          <textarea
            onChange={handledata}
            name="puntos"
            className="titulo"
            type={"string"}
            placeholder="Puntos"
          ></textarea>
          {/*<textarea
            onChange={handledata}
            name="observacion"
            className="titulo"
            type={"string"}
            placeholder="Observacion"
          ></textarea>*/}
          <a onClick={add}>
            <i className="bi bi-plus-square cursor-p"></i>
          </a>
        </form>  
      )}
    </>
  );
};
export default FormEquipments;
