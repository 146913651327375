import React, { useEffect, useState } from "react";
import moment from "moment";
import { actualizarDatos, actualizarEquipo, getDataList, postDataEquipos } from "../../api/common";
import Spinner from "../commons/Spinner";
import { paginate } from "../../api/paginate";
import { Pagination } from "../commons/Pagination";
import { useNavigate } from "react-router-dom";
import { Modales } from "../Modales";
import { Editareq } from "../Equipo/Editareq";
import SelectSearch from "../commons/SelectSearch";
import { useTheme } from "../../hooks/useTheme";
import Select from "react-dropdown-select";
import { Alertassw } from "../Alertas/Alertassw";
moment.locale("es");
const listSede={}

let sedeOn=[];

export const Historial = ({
  cliente,
  addequipos,
  listarequipos,
  pestaña,
  look,
  filter,
  refresh = null,
  sede

}) => {
  const [page, setPage] = useState(1);
  const [state, setState] = useState(false);
  const [cambio, setcambio]=useState(false)
  const [equipo, setEquip] = useState(null);
  const [filters, setFilters] = useState(null);
  const [listSede, setListSede]=useState([ ])
  const [spinnerstate, setSpinnerstate]=useState(false)
  const [sedesCliente, setSedesClient]=useState()
  const [query, setQuery]=useState("")
  
  const {size} = useTheme()
  const navigate = useNavigate();
  const changePage = (page) => {
    setHistorialeq({ ...equipos, results: {} });
    setPage(page);
  };
  
  const handleSetFilters = (e) => {
    if(filters?.sede)
     delete filters.sede
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };
  const [Eqselect, setEqselect] = useState([]);
  const [equiposIni, setHistorialeq] = useState([]);

  
  const selectSede=(e)=>{
    console.log(e[0].value)
    if(e[0].value==='Todas'){
      console.log('todas las sedes')
      setFilters({
        ...filters,
        sede:undefined
      })
      let sedesPrincipal=sedesCliente.filter(sede=>sede.nameSede==='Principal')
      sedeOn=[sedesPrincipal[0]]

    }
    if(e[0].value!=='Todas'){
    console.log('Entrando a modificar sede')
    sedeOn=sedesCliente.filter(sede=>sede.nameSede===e[0].value)
    setFilters({
      ...filters,
      sede:e[0].value
    })
  }
  }

  useEffect(() => {
    if (cliente || look ) {
      setSpinnerstate(true)
      const conditions = cliente
        ? sede? { cliente: cliente._id, sede:sede, ...filter, ...filters} :{ cliente: cliente._id, ...filter, ...filters}
        : { ...filter, ...filters };
      async function postEquipos() {
        console.log(conditions)
        if(conditions.cliente || cliente){

        const sedesDB = await getDataList("Sedes",{cliente:conditions.cliente});
        console.log(sedesDB.data.data)
        setSedesClient(sedesDB.data.data)
        const equiposDB = await getDataList('Equipos',
          {
            conditions,
            sort: {
              _id: -1,
            },
          }
        );
        if (equiposDB.status === 200 && equiposDB.data.data.length>0){
          setSpinnerstate(false)
          const listaSede=equiposDB.data.data[0].cliente.sedes.map((sede)=>({
            label:sede.nameSede,
            value:sede.nameSede
          }))
          listaSede.push(
            {
            label:'Principal',
            value:'Principal'
          },
          {
            label:'Todas',
            value:'Todas'
          },
          )
          setListSede(listaSede)
          setHistorialeq(equiposDB.data.data);
        }else{
          setSpinnerstate(false)
          setHistorialeq([]);
        }
      }

      }
      postEquipos();
    } else {
    //  //console.log("cliente no seleccionado");
    }
  }, [page, cliente, refresh, state, filters, sede, cambio]);

  const handlecheckbox = (equipo) => {
    //Con la funcion filter reviso si el equipo esta seleccionado
    const busqueda = Eqselect.filter((listsel) => listsel._id == equipo._id);
    //si el equipo esta seleccionado lo elimino de la lista
    if (busqueda.length > 0) {
  //    //console.log("quitando el equipo");
      const addsel = Eqselect.filter((listsel) => listsel._id !== equipo._id);
     // //console.log(addsel);
      setEqselect(addsel);
      //Si no esta seleccionado lo agrego a la lista
    } else {
      ////console.log("agregando equipo");
      setEqselect([
        ...Eqselect,
        {
          _id: equipo._id,
          cliente: equipo.cliente,
          item: equipo.item._id,
          marca: equipo.marca,
          modelo: equipo.modelo,
          serie: equipo.serie,
          inventario: equipo.inventario,
          ubicacion: equipo.ubicacion,
          resolucion: equipo.resolucion,
          puntos: equipo.puntos,
          observacion: equipo.observacion,
          nombreitem: equipo.item.nombre,
        },
      ]);
    }
  };
  const listar = () => {
   // //console.log(Eqselect);
    listarequipos([...addequipos, ...Eqselect]);
    pestaña(false);
  };
  const editEquipmet = (eq) => {
    setState(true);
    setEquip(eq);
  };
  const modificaSede=()=>{
  async function  Editareq (){

    try {
      console.log(sedeOn[0]._id)
      equiposIni.map(async(equipo)=>{
       let actualizado=await actualizarDatos('Equipos',{
        sedeClient:sedeOn[0]._id,
        _id:equipo._id
      },equipo._id)
        console.log(actualizado)
      })

      Alertassw('success','Equipos actualizados Correctamente')
      
    } catch (error) {
      Alertassw('error','No se pudo actualizar los equipos')
    }
}

Editareq();

  }

  console.log(equiposIni)
  const keys=["marca","modelo","serie","sede","resolucion","puntos","inventario","ubicacion"] 
  const equipos = !query? equiposIni : 

 
  equiposIni.filter((equipo)=>
  keys.some((key)=> 
    equipo[key]? equipo[key].toLowerCase().includes(query.toLowerCase()):
    equipo.item[key]? equipo.item[key].toLowerCase().includes(query.toLowerCase()): false 
    )
);
  return (
    <div>
      {look && (
        <div className="flex space-between">
          <div className="form_group ">
            <div className="form_group_label">Cliente</div>
            <SelectSearch
              handledata={handleSetFilters}
              name="cliente"
              collection={"Usuarios"}
              field={"nombre"}
              baseFilter={{ "role.nombre": "Cliente" }}
            />
            </div>

            {filters?.cliente?
            <div className="form_group ">
              <div className="form_group_label">Sede</div>
              <Select  searchable='true' onChange={selectSede} options={listSede} placeholder={"Todas"}/>
            </div>:<div></div>}
            {sedeOn.length>0?
            <button className="btn-btn-primary" onClick={modificaSede}>modificar sedes</button>:null}
          </div>
      )}
        <input
          type="text"
          placeholder="Ingresa un dato del item a buscar"
          className="form-control"
          onChange={(e)=> setQuery(e.target.value)}
        />
        <hr/>
      <ul className="historial cabeceraitem">
        <li className="titulo ">Item</li>
        <li className="titulo"> Marca </li>
        <li className="titulo"> Modelo </li>
        <li className="titulo"> Serial </li>
        <li className="titulo"> Inventario </li>
        <li className="titulo"> Ubicación </li>
        <li className="titulo"> Sede </li>
        {addequipos ? (
          <i class="bi bi-arrow-down-square UP_select" onClick={listar}></i>
        ) : null}
      </ul>
      <div>
        { equipos?.length === 0? 'No hay Equipos Registados':
          equipos?.length > 0 ? (
          equipos.map((eq, index) => (
            <div className=" historial sombra-button" key={eq._id}>
              <div
                className="under_line"
                onClick={() => {
                  navigate(`/perfilEq?_id=${eq._id}`);
                }}
              >
                {eq?.item?.nombre + " "+index}
              </div>
              <div>{eq?.marca}</div>
              <div>{eq?.modelo}</div>
              <div>{eq?.serie}</div>
              <div>{eq?.inventario}</div>
              <div>{eq?.ubicacion}</div>
              <div>{eq?.sedeClient?.nameSede}</div>
              {addequipos ? (
                <input
                  onChange={() => handlecheckbox(eq)}
                  className="checkboox"
                  name={`${eq.equipo?._id}`}
                  type={"checkbox"}
                ></input>
              ) : 
              <div>
                <div onClick={() => editEquipmet(eq)}>
                  {" "}
                  <i className="bi bi-pencil-square cursor-p font_sz"></i>
                </div>  
              </div>
              }
              {look && (
                <div onClick={() => editEquipmet(eq)}>
                  {" "}
                  <i className="bi bi-pencil-square cursor-p font_sz"></i>
                </div>
              )}
            </div>
          ))
        ) : !(filters?.cliente || cliente)?
        <div>Selecciona un cliente</div>:
        <>
        {spinnerstate? 
         (
        <Spinner />
        ):<div>No Hay Equipos Registrados</div>}
        </>}

        { equipos.results?.length > 0 && <Pagination
          prev={equipos.prev}
          next={equipos.next}
          total_pages={equipos.total_pages}
          changePage={changePage}
          page={page}
        />}
   </div> 
      <Modales estado={state} cambiarestado={setState} titulo={`Editar Equipo ${equipo?.marca || 'N/A'} - ${equipo?.modelo || 'N/A'} - ${equipo?.serie || 'N/A'}`}>
        <Editareq  infoOt={''} cambiarestado={setState} refresh={cambio} infoequipo={{equipo}} refrescar={setcambio} origen={'historial'} />
      </Modales>
    </div>
  );
};
